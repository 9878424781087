import { PDFExport } from "@progress/kendo-react-pdf";
import { process } from "@progress/kendo-data-query";
import { IIssue } from "models/issue";
import { IMessage } from "models/message";
import React, { useRef, useState } from "react";
import Aux from "utils/auxiliary";
import dateTimeFormat from "utils/dateTimeFormat";
import { IQcCheckpoint } from "models/QualityControl/qcCheckpoint";
import IssueImage from "./IssueImage";
import { IUser } from "models/user";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Button, Col, Row } from "react-bootstrap";

export interface IProps {
  currentUser: IUser;
  issue: IIssue;
  messages: IMessage[];
  checkpoints: IQcCheckpoint[];
}

const IssuePdfExport: React.FC<IProps> = (props) => {
  const { currentUser, issue, messages, checkpoints } = props;

  const [dataState] = useState({
    group: [{ field: "tagNames" }],
  });

  const pdfEl = useRef<PDFExport>(null);
  const onPdfExportClick = () => {
    if (pdfEl.current !== null) {
      pdfEl.current.save();
    }
  };

  const parseMessage = (message: string): JSX.Element[] => {
    if (
      !message.includes("#{") &&
      !message.includes("€{") &&
      !message.includes("%{") &&
      !message.includes("&{") &&
      !message.includes("${") &&
      !message.includes("£{") &&
      !message.includes("@{") &&
      !message.includes("*{")
    ) {
      return [<span key={"msgarray0"}>{message}</span>];
    }

    const messageArray = new Array<JSX.Element>();
    const splitRegex = message.split(
      /(#{[0-9]* - .*?}|€{[0-9]* - .*?}|@{.*?}|%{.*?}|&{[0-9]* - .*?}|[$]{[0-9]*}|\*{[0-9]*}|£{.*?}|https:\/\/[-A-Z0-9+&@#/%=~_|$?!:,.]*)/gi
    );
    for (var regexCount = 0; regexCount < splitRegex.length; regexCount++) {
      const word = splitRegex[regexCount];
      const productMatches = word.trim().match(/(^#{[0-9]+)/g);
      const mainCausePartsMatches = word.trim().match(/(€{[0-9]+)/g);
      const chassisMatches = word.trim().match(/(^%([^}])+)/g);
      const relIssueMatches = word.trim().match(/(^&{[0-9]+)/g);
      const orderMatches = word.trim().match(/(^[$]{[0-9]+)/g);
      const jigMatches = word.trim().match(/(^£([^}])+)/g);
      const userMatches = word.trim().match(/(^@([^}])+)/gi);
      const sfCaseMatches = word.trim().match(/(^\*{[0-9]+)/g);
      const hyperLinkMatches = word
        .trim()
        .match(
          /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim
        );

      if (
        productMatches === null &&
        mainCausePartsMatches === null &&
        chassisMatches === null &&
        relIssueMatches === null &&
        orderMatches === null &&
        jigMatches === null &&
        userMatches === null &&
        sfCaseMatches == null &&
        hyperLinkMatches == null
      ) {
        messageArray.push(<span key={"msgarray" + regexCount}>{word}</span>);
        continue;
      }
      let productId = "";
      let mainCausePartId = "";
      let chassisNo = "";
      let relIssueId = "";
      let orderNo = "";
      let caseNo = "";
      let jignr = "";
      let userName = "";

      if (mainCausePartsMatches && mainCausePartsMatches.length > 0) {
        mainCausePartId = mainCausePartsMatches[0].slice(2);
      }
      if (productMatches && productMatches.length > 0) {
        productId = productMatches[0].slice(2);
      }
      if (chassisMatches && chassisMatches.length > 0) {
        chassisNo = chassisMatches[0].slice(2);
      }
      if (relIssueMatches && relIssueMatches.length > 0) {
        relIssueId = relIssueMatches[0].slice(2);
      }
      if (orderMatches && orderMatches.length > 0) {
        orderNo = orderMatches[0].slice(2);
      }
      if (jigMatches && jigMatches.length > 0) {
        jignr = jigMatches[0].slice(2);
      }
      if (userMatches && userMatches.length > 0) {
        userName = userMatches[0].slice(2);
      }
      if (sfCaseMatches && sfCaseMatches.length > 0) {
        caseNo = sfCaseMatches[0].slice(2);
      }

      if (mainCausePartId) {
        messageArray.push(
          <span
            key={"msgarray" + regexCount}
          >{`Main cause part ${mainCausePartId} `}</span>
        );
        continue;
      }
      if (productId) {
        messageArray.push(
          <span key={"msgarray" + regexCount}>{`Product ${productId} `}</span>
        );
        continue;
      }
      if (chassisNo) {
        messageArray.push(
          <span key={"msgarray" + regexCount}>{`Chassis ${chassisNo} `}</span>
        );
        continue;
      }
      if (relIssueId) {
        messageArray.push(
          <span key={"msgarray" + regexCount}>{`Case ${relIssueId} `}</span>
        );
        continue;
      }
      if (orderNo) {
        messageArray.push(
          <span key={"msgarray" + regexCount}>{`Order ${orderNo} `}</span>
        );
        continue;
      }
      if (jignr) {
        messageArray.push(
          <span key={"msgarray" + regexCount}>{`Equipment ${jignr} `}</span>
        );
        continue;
      }
      if (userName) {
        messageArray.push(
          <span key={"msgarray" + regexCount}>{userName}</span>
        );
        continue;
      }
      if (caseNo) {
        messageArray.push(
          <span
            key={"msgarray" + regexCount}
          >{`Salesforce Case ${caseNo} `}</span>
        );
        continue;
      }

      messageArray.push(<span key={"msgarray" + regexCount}>{word}</span>);
    }
    return messageArray;
  };

  const mql = window.matchMedia("(min-width: 800px)");

  return (
    <Aux>
      <Button
        style={{
          textAlign: "center",
          marginBottom: mql.matches ? "40px" : "10px",
        }}
        className="k-button"
        onClick={onPdfExportClick}
      >
        Export to PDF
      </Button>
      <div
        style={{
          position: "absolute",
          left: "-5000px",
          top: 0,
          color: "black",
        }}
      >
        <PDFExport
          paperSize="A4"
          margin="1cm"
          fileName={issue.title || "Hms case"}
          ref={pdfEl}
          repeatHeaders={true}
          keepTogether="div"
        >
          <div
            className="chassis-pdf"
            style={{ fontSize: "12px", backgroundColor: "white" }}
          >
            <Row>
              <Col className="col-12 col-md-6">
                <h4>{issue.title}</h4>
              </Col>
              <Col className="col-12 col-md-6">
                <img
                  src={
                    "/assets/images/" +
                    (issue.issueTypeId === 5
                      ? "orkel_direkte_logo_ny_inv.png"
                      : "orkel_logo.svg")
                  }
                  crossOrigin="anonymous"
                  alt="Orkel logo"
                  className="logo float-end"
                />
              </Col>
            </Row>
            <p>
              Responsible:{" "}
              {issue.responsible !== undefined && issue.responsible !== null
                ? issue.responsible.fullName
                : ""}
            </p>
            {issue.issueTypeId === 6 && (
              <p>
                Event Date:{" "}
                {issue.hmsEventDate !== undefined
                  ? dateTimeFormat.format(new Date(issue.hmsEventDate))
                  : ""}
              </p>
            )}
            <h6>Messages:</h6>
            {messages
              .filter((m) => !m.deletedAt && m.id !== undefined)
              .map((m) => {
                return (
                  <div key={m.id}>
                    <p className="mt-0 mb-1">
                      {parseMessage(m.body)} -{" "}
                      {m.user.fullName +
                        " " +
                        dateTimeFormat.format(new Date(m.createdAt))}
                    </p>
                    {m.images &&
                      m.images.map((image) => (
                        <div
                          className="mb-3"
                          key={"image-" + image.id}
                          style={{ width: "70%", height: "70%" }}
                        >
                          <IssueImage
                            currentUser={currentUser}
                            editMode={false}
                            image={image}
                            message={m}
                          />
                        </div>
                      ))}
                  </div>
                );
              })}
            <br />
            <div>
              <h6>Checklist:</h6>
              {process(checkpoints, dataState)
                .data.sort((a, b) =>
                  a.value ? (b.value ? a.value.localeCompare(b.value) : -1) : 1
                )
                .map((i) => {
                  return (
                    <div key={i.value}>
                      <p className="mb-0">{i.value || "Case Checkpoints"}:</p>
                      {i.items
                        .map((c: IQcCheckpoint) => {
                          var checkpointTag = c.tagCheckpoints.find(
                            (t) => t.tagName === i.value
                          );
                          if (checkpointTag !== undefined) {
                            c.sort = checkpointTag.sort;
                          }
                          return c;
                        })
                        .sort((a, b) => a.sort - b.sort)
                        .map((item) => {
                          var inspection = issue.inspections.find(
                            (i) => i.qcCheckpointId === item.id
                          );
                          return (
                            <div
                              style={{ flexGrow: 1 }}
                              key={item.id.toString()}
                            >
                              {!item.notCheckable ? (
                                <Checkbox
                                  className="checklist-checkpoint"
                                  name="inspection"
                                  id={item.id.toString()}
                                  label={item.name}
                                  style={{ height: "17px", width: "17px" }}
                                  checked={
                                    inspection?.qcStatusId === 2
                                      ? inspection.notApplicable
                                        ? false
                                        : true
                                      : false
                                  }
                                />
                              ) : (
                                <p className="px-2 pt-2 mb-0">{item.name}</p>
                              )}
                              {!item.notCheckable && (
                                <Col
                                  xs={18}
                                  className="text-muted text-start"
                                  style={{ width: "100%" }}
                                >
                                  <small>
                                    {inspection &&
                                    inspection.userName &&
                                    (inspection.qcStatusId === 2 ||
                                      inspection?.notApplicable) ? (
                                      <em>
                                        {inspection?.notApplicable
                                          ? "Set to not applicable by: "
                                          : "Checked by: "}{" "}
                                        {inspection.userName}{" "}
                                        {dateTimeFormat.format(
                                          new Date(inspection.updatedAt)
                                        )}
                                      </em>
                                    ) : (
                                      <span>&nbsp;</span>
                                    )}
                                  </small>
                                </Col>
                              )}
                              {inspection?.note &&
                                inspection?.note.length > 0 && (
                                  <p className="mb-1 pb-1">
                                    Note: {inspection.note}
                                  </p>
                                )}
                            </div>
                          );
                        })}
                      <br />
                    </div>
                  );
                })}
            </div>
            {issue.userSignatures && issue.userSignatures.length > 0 && (
              <div>
                <p>
                  Signature by {issue.userSignatures[0].userName} at
                  {" " +
                    dateTimeFormat.format(
                      new Date(issue.userSignatures[0].createdAt)
                    )}
                </p>
                <img
                  src={issue.userSignatures[0].signature}
                  alt="signature"
                  style={{ width: "70%", height: "70%" }}
                />
              </div>
            )}
          </div>
        </PDFExport>
      </div>
    </Aux>
  );
};

export default IssuePdfExport;
